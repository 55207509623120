import * as Sentry from "@sentry/browser"
import { Severity } from "@sentry/browser"

export const log = {
  event: (event: string, properties?: object) => {
    console.log("[METRICS]", event, properties)
  },
  error: (message: any, extra?: {}) => {
    console.error(message)
    Sentry.captureEvent({
      message,
      level: Severity.Error,
      extra,
    })
  },
  exception: (e: any) => {
    Sentry.captureException(e)
  },
}
