import "firebase/compat/auth"
import firebase from "firebase/compat/app"

const firebaseConfig = {
  apiKey: "AIzaSyDfvzoNNslvQMjHZtWm7cQ5I0d95ZQSzSc",
  authDomain: "adventurekeep-5cac7.firebaseapp.com",
  databaseURL: "https://adventurekeep-5cac7.firebaseio.com",
  projectId: "adventurekeep-5cac7",
  storageBucket: "adventurekeep-5cac7.appspot.com",
  messagingSenderId: "321885822494",
  appId: "1:321885822494:web:765486d881dc38b4d9580f",
  measurementId: "G-DBBZ5Q6JQY",
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export { firebase }
