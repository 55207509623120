import { ToggleLeft, ToggleRight } from "./svg"

export const Toggle = ({
  value,
  onChange,
  className,
}: {
  className?: string
  value: boolean
  onChange: (v: boolean) => void
}) => {
  return (
    <button className={className} onClick={() => onChange(!value)}>
      {value ? (
        <ToggleRight
          height={32}
          width={32}
          style={{ fill: "var(--blue-500)" }}
        />
      ) : (
        <ToggleLeft
          height={32}
          width={32}
          style={{ fill: "var(--gray-600)" }}
        />
      )}
    </button>
  )
}

export const ToggleOutline = ({
  value,
  onChange,
  className,
}: {
  className?: string
  value: boolean
  onChange: (v: boolean) => void
}) => {
  return (
    <button
      aria-label={`toggle ${value ? "off" : "on"}`}
      className={className}
      onClick={() => onChange(!value)}
    >
      {value ? (
        <ToggleRight style={{ stroke: "#fff" }} height={32} width={32} />
      ) : (
        <ToggleLeft style={{ stroke: "#fff" }} height={32} width={32} />
      )}
    </button>
  )
}
