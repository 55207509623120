import React from "react"
import * as Sentry from "@sentry/browser"
import { trpc } from "utils/trpc"

let userId: string | undefined

export type User = {
  id: string
  email: string
  firstName: string
  lastName: string
  pictureUrl: string | void
  subscription: "PRO" | "FREE"
  activeAdventure: {
    id: string
    pages: Array<{ id: string }>
  }
}

export const useMe = () => {
  const response = trpc.me.get.useQuery()

  userId = response.data?.id

  React.useEffect(() => {
    if (userId) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: response.data?.id,
          email: response.data?.email,
        })
      })
    }
  }, [userId])

  const isPro = response?.data?.subscription === "PRO"
  const me = response.data

  return {
    ...response,
    me,
    userId,
    isPro,
  }
}
